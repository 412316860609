<template>
  <div>
    <d-button
      :text="selectedModel ? 'admin.duplicate.block-price.without-model' : 'admin.duplicate.block-price.with-model'"
      class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
      @on:button-click="scrollToBlockPriceDuplicateList"
    />

    <block-price-list
      v-if="selectedModel || getSelectedBlockPrice"
    />

    <div v-if="getSelectedBlockPrice" class="border-bottom-grey mt-4 mb-4"/>

    <block-price-list-duplicate
      v-show="!selectedModel || getSelectedBlockPrice"
      id="blockPriceListDuplicateId"
    />
  </div>
</template>
<script>

export default {
  components: {
    BlockPriceList: () => import('./BlockPriceList'),
    BlockPriceListDuplicate: () => import('./BlockPriceListDuplicate')
  },
  data: () => ({
    duplicationModel: false,
    search: '',
    withModel: false,
    selectedModel: false,
  }),
  computed: {
    getSelectedBlockPrice() {
      return this.$store.getters['blockPrice/getSelectedBlockPriceModel'];
    }
  },
  methods: {
    scrollToBlockPriceDuplicateList() {
      this.selectedModel = !this.selectedModel;

      this.$nextTick(() => {
        this.$store.commit('blockPrice/setSelectedBlockPriceModel', null);
        setTimeout(() => {
          document.getElementById('blockPriceListDuplicateId').scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 300)
        ;
      });
    },
  },
  mounted() {
    this.$store.commit("blockPrice/setCheckedBlockPriceList", []);
    this.$store.commit("blockPrice/setSelectedBlockPriceModel", null);
  }
}
</script>
